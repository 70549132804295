@use "../../variables.scss";

.work-section-container {
  max-width: 1000px;
  margin: auto;

  .projects-list {
    .flex-row {
      flex-direction: row;
    }

    .flex-row-reverse {
      flex-direction: row-reverse;
    }

    .project-image {
      .project-img-lg {
        width: 600px;
      }
      .project-img-sm {
        width: 360px;
      }
    }

    .project-list-item {
      display: flex;
      margin-bottom: 100px;
      align-items: center;
      gap: 60px;

      .project-content {
        .text-right {
          text-align: right;
        }
        .text-left {
          text-align: left;
        }
        .project-head {
          p,
          h3 {
            margin-bottom: 16px;
          }
        }

        .project-summary {
          line-height: 1.2rem;
          margin-bottom: 20px;
        }

        .project-technologies {
          display: flex;
          flex-flow: wrap;
          li {
            padding-right: 10px;
            color: #a7b3d2;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .work-section-container {
    .projects-list {
      .flex-row {
        flex-direction: column;
      }

      .flex-row-reverse {
        flex-direction: column;
      }

      .project-list-item {
        .project-image {
          .project-img-lg {
            width: 300px;
          }
          .project-img-sm {
            width: 200px;
          }
        }
        .project-content {
          text-align: left;
          .text-right,
          .text-left {
            text-align: left;
          }
        }
      }
    }
  }
}
