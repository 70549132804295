@use "../../variables.scss";

#contact {
  padding-bottom: 200px;
  text-align: center;
  h3,
  h2 {
    margin-bottom: 40px;
  }
  h3 {
    color: variables.$accent-color;
  }

  a {
    display: inline-block;
    padding: 15px;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
    font-size: 1.2rem;
    background-color: variables.$accent-color;
    border: 2px solid variables.$accent-color;
    color: variables.$primary-color;
    &:hover {
      background-color: variables.$primary-color;
      color: variables.$accent-color;
    }
  }
}
